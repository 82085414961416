import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Situation } from '../shared/models/situation.model';
import { GameConfig } from '../shared/models/game-config.model';
import { JudgeConfig } from '../shared/models/judge-config.model';
import { GameMode } from '../shared/models/game-mode.model';

/**
 * Service for data operations via http.
 *
 * @export
 * @class DataService
 */
@Injectable({
    providedIn: 'root',
})
export class DataService
{
    constructor(private http: HttpClient)
    {
    }

    /**
     * Gets a json file and converts it for javascript.
     *
     * @param {string} url The url to request the data from.
     *
     * @returns {Observable<any>}
     * @memberof DataService
     */
    private getJson(url: string): Observable<any>
    {
        return this.http.get(`${url}?`);
    }

    /**
     * Gets the game mode.
     *
     * @returns {Observable<GameMode>}
     * @memberof DataService
     */
    public getGameMode(): Observable<GameMode>
    {
        return this.getJson('./assets/config/game-mode.config.json');
    }


    /**
     * Gets the game configuration.
     *
     * @param {<GameMode>} gameMode The game mode the game is played in.
     * @returns {Observable<GameConfig>}
     * @memberof DataService
     */
    public getGameConfig(gameMode: GameMode): Observable<GameConfig>
    {
        return this.getJson(`./assets/config/game-${gameMode.mode}.config.json`);
    }

    /**
     * Gets the judge configuration.
     *
     * @returns {Observable<JudgeConfig>}
     * @memberof DataService
     */
    public getJudgeConfig(): Observable<JudgeConfig>
    {
        return this.getJson('./assets/config/judge.config.json');
    }

    /**
     * Gets all situations.
     *
     * @param {<GameMode>} gameMode The game mode the game is played in.
     * @returns {Observable<Situation[]>}
     * @memberof DataService
     */
    public getSituations(gameMode: GameMode): Observable<Situation[]>
    {
        return this.getJson(`./assets/data/situations-${gameMode.mode}.data.json`);
    }
}
