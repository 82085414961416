import { Component } from '@angular/core';
import { IconRegistryService } from './core/icon-registry.service';
import { Router } from '@angular/router';

@Component({
    selector: 'fh-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent
{
    constructor(private iconRegistryService: IconRegistryService, public router: Router)
    {
        this.iconRegistryService.init();
    }
}
