import { Component } from '@angular/core';

@Component({
    selector: 'fh-step',
    styleUrls: ['./step.component.scss'],
    templateUrl: './step.component.html',
})
export class StepComponent
{
}
