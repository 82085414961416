import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { style, animate, animation, AnimationBuilder } from '@angular/animations';

@Component({
    selector: 'fh-progress-bar',
    styleUrls: ['./progress-bar.component.scss'],
    templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent implements OnChanges
{
    @Input() progressInPercent = '0%';
    @ViewChild('indicator', { static: true }) indicator: ElementRef;

    constructor(private animationBuilder: AnimationBuilder)
    {
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        const previousProgress: string = changes.progressInPercent.previousValue;
        const currentProgress: string = changes.progressInPercent.currentValue;

        this.animate(previousProgress, currentProgress);
    }

    /**
     * Animates the indicator of the progress bar
     *
     * @param {string} previousProgress The previous progress in percent.
     * @param {string} currentProgress The current progress in percent.
     * @memberof ProgressBarComponent
     */
    animate(previousProgress: string, currentProgress: string): void
    {
        const previousWidth = previousProgress ? previousProgress : '0%';
        const currentWidth = currentProgress ? currentProgress : '0%';

        const progressAnimation = this.animationBuilder.build
        (
            animation
            (
                [
                    style
                    (
                        { width: '{{ startWidth }}' }
                    ),
                    animate('{{  duration  }}', style({ width: '{{ endWidth }}' })),
                    style
                    (
                        { width: '{{ endWidth }}' }
                    ),
                ],
                {
                    params: { duration: '200ms ease-in', endWidth: currentWidth, startWidth: previousWidth },
                }
            )
        );

        const player = progressAnimation.create(this.indicator.nativeElement);
        player.play();
    }
}
