import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper/stepper.component';
import { StepComponent } from './stepper/step/step.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatIconModule, MatTabsModule } from '@angular/material';
import { OverlayComponent } from './overlay/overlay.component';

@NgModule({
    declarations:
    [
        StepperComponent,
        StepComponent,
        ProgressBarComponent,
        OverlayComponent,
    ],
    exports:
    [
        CommonModule,
        MatIconModule,
        MatTabsModule,
        OverlayComponent,
        StepperComponent,
        StepComponent,
        ProgressBarComponent,
    ],
    imports: [CommonModule, MatIconModule, MatTabsModule],
})
export class SharedModule
{ }
