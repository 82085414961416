import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes =
[
    {
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        path: '',
    },
    {
        loadChildren: () => import('./situation/situation.module').then((m) => m.SituationModule),
        path: 'situation',
    },
    {
        loadChildren: () => import('./score/score.module').then((m) => m.ScoreModule),
        path: 'score',
    },
    {
        loadChildren: () => import('./ai-functioning/ai-functioning.module').then((m) => m.AiFunctioningModule),
        path: 'ai-functioning',
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
})
export class AppRoutingModule
{ }
