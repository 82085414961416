import { Component } from '@angular/core';

@Component({
    selector: 'fh-overlay',
    styleUrls: ['./overlay.component.scss'],
    templateUrl: './overlay.component.html',
})
export class OverlayComponent
{

}
