import { Injectable } from '@angular/core';
import { Situation } from '../shared/models/situation.model';
import { SituationOutcome } from '../shared/models/situation-outcome.model';
import { JudgeConfig } from '../shared/models/judge-config.model';
import { DataService } from './data.service';
import { Score } from '../shared/models/score.model';
import { Point } from '../shared/models/point.model';
import { BoundingBox } from '../shared/models/bounding-box.model';

@Injectable({
    providedIn: 'root',
})
export class JudgeService
{
    private judgeConfig: JudgeConfig;

    constructor(private dataService: DataService)
    {
        this.getJudgeConfig();
    }

    /**
     * Judges a situation by calculating the outcome and the score for each player.
     *
     * @param {Situation} situation The situation to judge.
     * @returns {SituationOutcome}
     * @memberof JudgeService
     */
    public judgeSituation(situation: Situation): { [player: string]: SituationOutcome }
    {
        const situationOutcome = {};

        for (const player in situation.players)
        {
            const message = this.calculateSituationOutcome(situation, player);
            const score = this.calculateSituationScore(situation, player);

            situationOutcome[player] = { message, score };
        }

        return situationOutcome;
    }

    /**
     * Calculates the total score for each player across situations.
     *
     * @param {Situation[]} situations An array of Situations to calculate the total score for.
     * @returns {{ [player: string]: Score }}
     * @memberof JudgeService
     */
    public calculateTotalScores(situations: Situation[]): { [player: string]: Score }
    {
        const scores: { [player: string]: Score } = {};

        situations.forEach
        (
            (situation) =>
            {
                for (const player in situation.players)
                {
                    // We need to make sure players exist before adding their score.
                    if (!scores[player])
                    {
                        scores[player] = 0;
                    }

                    scores[player] += situation.outcome[player].score;
                }
            }
        );

        return scores;
    }

    /**
     * Calculates the highest possible score for the given situations.
     *
     * @param {Situation[]} situations An array of Situations to calculate the highest possible score for.
     * @returns {number}
     * @memberof JudgeService
     */
    public calculateHighestPossibleScore(situations: Situation[]): number
    {
        return situations.length * this.judgeConfig.highestScorePerSituation;
    }

    /**
     * Calculates the situation outcome for a player.
     *
     * @param {Situation} situation
     * @param {string} player The player to calculate the outcome for.
     * @returns {string[]} A string containing HTML markup representing the outcome of the situation for the player.
     * @memberof JudgeService
     */
    private calculateSituationOutcome(situation: Situation, player: string): string
    {
        const timeTillInput = situation.input[player].timeTillInput;
        const pointerPosition = situation.input[player].pointerPosition;

        switch (situation.kind)
        {
            case 'emergency-braking':

                if (timeTillInput >= situation.videoLength)
                {
                    return '<strong>What?!</strong><br>You did not slow down <em>at all.</em>';
                }
                else if (timeTillInput < situation.timeTillPossibleRecognition)
                {
                    return '<strong>Oh no!</strong><br>You slowed down <em>too early.</em>';
                }
                else if (timeTillInput >= situation.timeTillAccident)
                {
                    return '<strong>Oh no!</strong><br>You slowed down <em>too late.</em>';
                }

                return '<strong>Good Job!</strong><br>You slowed down <em>in time.</em>';
                break;

            case 'recognition':

                if (timeTillInput >= situation.videoLength)
                {
                    return '<strong>What?!</strong><br>You did not tap <em>at all.</em>';
                }
                else if (!this.isPointInBoundingBox(pointerPosition, situation.objectBoundingBox))
                {
                    return '<strong>Oh no!</strong><br>You tapped on the <em>wrong object.</em>';
                }

                return '<strong>Good Job!</strong><br>You tapped on the right <em>object.</em>';
                break;
        }
    }

    /**
     * Calculates the situation score for a player.
     *
     * @param {Situation} situation The situation to calculate the score for.
     * @param {string} player The player to calculate the score for.
     * @returns {number} The situation score for a player.
     * @memberof JudgeService
     */
    /* eslint-disable max-lines-per-function */
    private calculateSituationScore(situation: Situation, player: string): number
    {
        const timeTillInput = situation.input[player].timeTillInput;
        const pointerPosition = situation.input[player].pointerPosition;
        let score: number;

        switch (situation.kind)
        {
            case 'emergency-braking':

                if (timeTillInput === situation.videoLength)
                {
                    // User did not slowed down at all.
                    score = this.judgeConfig.lowestScorePerSituation;
                }
                else if (timeTillInput < situation.timeTillPossibleRecognition)
                {
                    // User slowed down too early.
                    score = this.judgeConfig.lowestScorePerSituation;
                }
                else if (timeTillInput >= situation.timeTillAccident)
                {
                    // User slowed down too late.
                    score = this.judgeConfig.lowestScorePerSituation;
                }
                else
                {
                    // User slowed down in time.
                    const timeFrame = situation.timeTillAccident - situation.timeTillPossibleRecognition;
                    const timeLeft = situation.timeTillAccident - timeTillInput;
                    const scoreInPercent = timeLeft * 100 / timeFrame;

                    score = this.judgeConfig.highestScorePerSituation / 100 * scoreInPercent;
                }
                break;

            case 'recognition':
                // User did not tap at all.
                if (timeTillInput >= situation.videoLength)
                {
                    score = this.judgeConfig.lowestScorePerSituation;
                }
                // User tapped on the wrong object
                else if (!this.isPointInBoundingBox(pointerPosition, situation.objectBoundingBox))
                {
                    score = this.judgeConfig.lowestScorePerSituation;
                }
                else
                {
                    // User tapped on the right object.
                    const timeFrame = situation.videoLength - situation.timeTillPossibleRecognition;
                    const timeLeft = situation.videoLength - timeTillInput;
                    const scoreInPercent = timeLeft * 100 / timeFrame;

                    score = this.judgeConfig.highestScorePerSituation / 100 * scoreInPercent;
                }
                break;
        }

        return this.judgeConfig.roundScore ? Math.round(score) : score;
    }
    /* eslint-enable max-lines-per-function */

    /**
     * Checks if a point is in a bounding box.
     *
     * @param {Point} point The point to check for.
     * @param {BoundingBox} boundingBox The bounding box to check for.
     * @returns {boolean} True if the pint is in the bounding box, false if not.
     * @memberof JudgeService
     */
    isPointInBoundingBox(point: Point, boundingBox: BoundingBox): boolean
    {
        const isInXSegment = point.x >= boundingBox.x && point.x <= boundingBox.x + boundingBox.width;
        const isInYSegment = point.y >= boundingBox.y && point.y <= boundingBox.y + boundingBox.height;

        return isInXSegment && isInYSegment ? true : false;
    }

    /**
     * Gets the game configuration.
     *
     * @private
     * @memberof JudgeService
     */
    private getJudgeConfig(): void
    {
        this.dataService.getJudgeConfig().subscribe
        (
            (data: JudgeConfig) =>
            {
                this.judgeConfig = data;
            }
        );
    }
}
