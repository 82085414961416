import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class IconRegistryService
{
    constructor(
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
    )
    {}

    init(): void
    {
        this.matIconRegistry.addSvgIcon('fraunhofer-logo', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/fraunhofer-logo.svg'));
        this.matIconRegistry.addSvgIcon('accident', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/accident.svg'));
        this.matIconRegistry.addSvgIcon('confirm', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/confirm.svg'));
        this.matIconRegistry.addSvgIcon('danger', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/danger.svg'));
        this.matIconRegistry.addSvgIcon('next', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/next.svg'));
        this.matIconRegistry.addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/info.svg'));
    }
}
