import { Score } from './score.model';

/**
 * A class that represents a player.
 *
 * @export
 * @class Player
 *
 * @param {string} displayName The name of the player that gets displayed.
 * @param {Type<Score>} score The calculated outcome score.
 */
export class Player
{
    constructor(
        public displayName: string,
        public score: Score = 0,
    )
    { }
}
