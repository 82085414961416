export class NavigationLink
{
    constructor(
      public label: string,
      public routerLink: string,
      public routerFragment?: string,

    )
    {}
}
