import { Injectable } from '@angular/core';
import { DataService } from '../core/data.service';
import { GameConfig } from '../shared/models/game-config.model';
import { Situation } from '../shared/models/situation.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JudgeService } from '../core/judge.service';
import { SituationState } from '../shared/models/situation-state.model';
import { GameMode } from '../shared/models/game-mode.model';
import { SituationInput } from '../shared/models/situation-input.model';

@Injectable({
    providedIn: 'root',
})
export class SituationService
{
    private situationPool: Situation[];
    private situations: Situation[];
    private currentSituationIndex: number;

    private situationsSubject = new BehaviorSubject<Situation[]>([]);
    private currentSituationSubject = new BehaviorSubject<Situation>({} as Situation);
    private showSituationTutorialSubject = new BehaviorSubject<boolean>(true);
    private showSituationOutcomeSubject = new BehaviorSubject<boolean>(false);
    private currentSituationIndexSubject = new BehaviorSubject<number>(0);
    private currentSituationStateSubject = new BehaviorSubject<SituationState>('situation-init');

    public situationsObservable = this.situationsSubject.asObservable();
    public currentSituationObservable = this.currentSituationSubject.asObservable();
    public currentSituationIndexObservable = this.currentSituationIndexSubject.asObservable();
    public currentSituationStateObservable = this.currentSituationStateSubject.asObservable();
    public showSituationTutorialObservable = this.showSituationTutorialSubject.asObservable();
    public showSituationOutcomeObservable = this.showSituationOutcomeSubject.asObservable();

    constructor(private dataService: DataService, private judgeService: JudgeService)
    {}

    /**
     * Initializes situations and informs subscribers.
     * Should only get called by game service.
     *
     * 1. Get all situations.
     * 2. Filter situations based on game configuration.
     *
     * @param {GameMode} gameMode The game mode the game is played in.
     * @param {GameConfig} gameConfig The game configuration.
     * @returns {Observable<Situation[]>}
     * @memberof SituationService
     */
    public initSituations(gameMode: GameMode, gameConfig: GameConfig): Observable<Situation[]>
    {
        return this.getSituations(gameMode).pipe
        (
            tap
            (
                () =>
                {
                    // Reset situations before pushing values makes restarting the game easier
                    this.situations = [];

                    // Filter situations based on game configuration
                    const possibleSituations = this.situationPool.filter
                    (
                        (situation) =>
                        {
                            const hasPossibleId = gameConfig.possibleSituations.includes(situation.id);
                            const isPossibleKind = gameConfig.possibleSituationKinds.includes(situation.kind);

                            return hasPossibleId && isPossibleKind ? true : false;
                        }
                    );

                    if (possibleSituations.length === 0)
                    {
                        throw new Error('No possible situations! Please adjust game.config.json.');
                    }

                    // Randomly pick a number of situations based on game configuration, each situation can only occur once
                    for (let i = 0; i < gameConfig.numberOfSituations; i++)
                    {
                        const randomSituationIndex = Math.floor(Math.random() * possibleSituations.length);
                        const randomSituation = possibleSituations[randomSituationIndex];

                        possibleSituations.splice(randomSituationIndex, 1);
                        this.situations.push(randomSituation);
                    }

                    // Reset subjects makes restarting the game easier
                    this.situationsSubject.next(this.situations);
                    this.currentSituationIndexSubject.next(0);
                    this.showSituationTutorialSubject.next(true);
                    this.showSituationOutcomeSubject.next(false);
                    this.currentSituationStateSubject.next('situation-init');

                    this.currentSituationIndexObservable.subscribe
                    (
                        (newSituationIndex: number) =>
                        {
                            this.onCurrentSituationIndexChange(newSituationIndex);
                        }
                    );
                }
            )
        );
    }


    /**
     * Complets the tutorial of the current situation.
     *
     * @memberof SituationService
     */
    public completeTutorial(): void
    {
        this.showSituationTutorialSubject.next(false);
        this.currentSituationStateSubject.next('situation-tutorial-completed');
    }

    /**
     * Starts the current situation.
     *
     * @memberof SituationService
     */
    public startSituation(): void
    {
        this.currentSituationStateSubject.next('situation-started');
    }

    /**
     * Continues to next situation.
     *
     * @memberof SituationService
     */
    public nextSituation(): void
    {
        this.currentSituationIndexSubject.next(this.currentSituationIndex + 1);
        this.showSituationOutcomeSubject.next(false);
        this.showSituationTutorialSubject.next(true);
    }

    /**
     * Ends the current situation.
     *
     * @param {SituationInput} situationInput The situation input of the human player.
     * @memberof SituationService
     */
    public endSituation(situationInput: SituationInput): void
    {
        this.currentSituationStateSubject.next('situation-over');

        const currentSituation = this.situations[this.currentSituationIndex];

        // Save player input
        currentSituation.input.human = situationInput;

        // Judge the situation
        currentSituation.outcome = this.judgeService.judgeSituation(currentSituation);

        this.showSituationOutcomeSubject.next(true);

        currentSituation.progressInPercent = 100;
    }

    /**
     * Gets all situations
     *
     * @param {GameMode} gameMode The game mode the game is played in.
     * @returns {Observable<Situation[]>}
     * @memberof SituationService
     */
    private getSituations(gameMode: GameMode): Observable<Situation[]>
    {
        return this.dataService.getSituations(gameMode).pipe
        (
            tap
            (
                (data: Situation[]) =>
                {
                    this.situationPool = data;
                }
            )
        );
    }

    /**
     * Performs action when the current situation index changes.
     *
     * @private
     * @param {number} nextSituationIndex The situation index of the next situation.
     * @memberof SituationService
     */
    private onCurrentSituationIndexChange(nextSituationIndex: number): void
    {
        this.currentSituationIndex = nextSituationIndex;
        this.currentSituationSubject.next(this.situations[nextSituationIndex]);
    }
}
