import { Component, OnInit, OnDestroy } from '@angular/core';
import { GameService } from '../game.service';
import { Observable, Subscription, zip } from 'rxjs';
import { GameState } from 'src/app/shared/models/game-state.model';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Situation } from 'src/app/shared/models/situation.model';
import { SituationService } from 'src/app/situation/situation.service';
import { NavigationLink } from 'src/app/shared/models/navigation-link.model';
import { Location } from '@angular/common';
import { GameMode } from 'src/app/shared/models/game-mode.model';

@Component({
    selector: 'fh-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy
{
    public gameStateObservable: Observable<GameState>;
    public situationsObservable: Observable<Situation[]>;
    public resultProgressInPercent = 0;
    public showCancelButton = false;
    public showRestartButton = false;
    public activeRoute = '/';
    public showAiNavLinks = false;

    public aiNavLinks: NavigationLink[] = [];

    private gameModeSubscription: Subscription;
    private routerSubscription: Subscription;
    private situationStateSubscription: Subscription;

    constructor(
        private gameService: GameService,
        private situationService: SituationService,
        private router: Router,
        private location: Location
    )
    { }

    ngOnInit(): void
    {
        this.gameStateObservable = this.gameService.gameStateObservable;
        this.situationsObservable = this.situationService.situationsObservable;

        zip
        (
            this.situationService.situationsObservable,
            this.gameService.gameModeObservable
        ).subscribe
        (
            (data: [Situation[], GameMode]) =>
            {
                this.aiNavLinks = this.createAiNavLinks(data[0], data[1]);
            }
        );

        // This.aiNavLinks = this.createAiNavLinks(gameMode);

        this.routerSubscription = this.router.events.subscribe
        (
            (routerEvent: Event) =>
            {
                if (routerEvent instanceof NavigationEnd)
                {
                    // Show ai nav links if current url is /ai-functioning or a child route
                    this.showAiNavLinks = /^\/ai-functioning\/?.*$/.test(routerEvent.url);
                }
            }
        );

        this.gameService.gameStateObservable.subscribe
        (
            (gameState: GameState) =>
            {
                if (gameState === 'game-over')
                {
                    this.resultProgressInPercent = 100;
                    this.showRestartButton = true;
                }
                else
                {
                    this.resultProgressInPercent = 0;
                    this.showRestartButton = false;
                }

                this.showCancelButton = gameState === 'game-started' ? true : false;
            }
        );
    }

    ngOnDestroy(): void
    {
        this.routerSubscription.unsubscribe();

        if (this.situationStateSubscription)
        {
            this.situationStateSubscription.unsubscribe();
        }
    }

    createAiNavLinks(situations: Situation[], gameMode: GameMode): NavigationLink[]
    {
        const aiNavLinks: NavigationLink[] = [];
        switch (gameMode.mode)
        {
            case 'emergency-braking':
                aiNavLinks.push(new NavigationLink('AI Explanation', 'ai-functioning'));
                break;

            case 'recognition':
                situations.forEach
                (
                    (situation, i) =>
                    {
                        aiNavLinks.push(new NavigationLink(`Situation ${i + 1}`, `ai-functioning/${situation.id}`));
                    }
                );
                break;
        }

        return aiNavLinks;
    }

    /**
     * Restarts the game.
     *
     * @memberof HeaderComponent
     */
    onRestartGame(): void
    {
        this.gameService.restartGame();
    }

    /**
     * Cancels the game.
     *
     * @memberof HeaderComponent
     */
    onCancelGame(): void
    {
        this.gameService.restartGame();
    }

    /**
     * Navigates back.
     *
     * @memberof HeaderComponent
     */
    onBack(): void
    {
        this.location.back();
    }
}
