import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Situation } from '../shared/models/situation.model';
import { GameConfig } from '../shared/models/game-config.model';
import { GameState } from '../shared/models/game-state.model';
import { SituationService } from '../situation/situation.service';
import { Player } from '../shared/models/player.model';
import { JudgeService } from './judge.service';
import { GameMode } from '../shared/models/game-mode.model';


/**
 * Service for managing the game.
 *
 * @export
 * @class GameService
 */
@Injectable({
    providedIn: 'root',
})
export class GameService
{
    private gameStateSubject = new BehaviorSubject<GameState>('game-init');
    private playersSubject = new BehaviorSubject<{ [ player: string ]: Player }>({});
    private gameModeSubject = new BehaviorSubject<GameMode>({ mode: null });

    private gameMode: GameMode;
    private gameConfig: GameConfig;
    private situations: Situation[];
    private players: { [ player: string ]: Player } = {};

    public gameModeObservable = this.gameModeSubject.asObservable();
    public playersObservable = this.playersSubject.asObservable();
    public gameStateObservable = this.gameStateSubject.asObservable();

    constructor(
        private router: Router,
        private dataService: DataService,
        private situationService: SituationService,
        private judgeService: JudgeService,
    )
    {
        this.gameStateObservable.subscribe
        (
            (gameState: GameState) =>
            {
                switch (gameState)
                {
                    case 'game-init':
                        this.onGameInit();
                        break;

                    case 'game-started':
                        this.onGameStarted();
                        break;

                    case 'game-over':
                        this.onGameOver();
                        break;

                    default:
                        break;
                }
            }
        );
    }

    /**
     * Starts the game.
     *
     * @memberof GameService
     */
    public startGame(): void
    {
        this.gameStateSubject.next('game-started');
    }

    /**
     * Ends the game.
     *
     * @memberof GameService
     */
    public gameOver(): void
    {
        this.gameStateSubject.next('game-over');
    }

    /**
     * Restarts the game.
     *
     * @memberof GameService
     */
    public restartGame(): void
    {
        this.gameStateSubject.next('game-init');
    }

    /**
     * Checks if the game is over.
     *
     * @returns {boolean}
     * @memberof GameService
     */
    public isGameOver(currentSituationIndex, situations: Situation[]): boolean
    {
        return currentSituationIndex === situations.length ? true : false;
    }

    /**
     * Gets the game mode.
     *
     * @private
     * @returns {Observable<GameMode>}
     * @memberof GameService
     */
    private getGameMode(): Observable<GameMode>
    {
        return this.dataService.getGameMode().pipe
        (
            tap
            (
                (data: GameMode) =>
                {
                    this.gameMode = data;
                    this.gameModeSubject.next(this.gameMode);
                }
            )
        );
    }

    /**
     * Gets the game configuration.
     *
     * @private
     * @param {<GameMode>} gameMode The game mode the game is played in.
     * @returns {Observable<GameConfig>}
     * @memberof GameService
     */
    private getGameConfig(gameMode: GameMode): Observable<GameConfig>
    {
        return this.dataService.getGameConfig(gameMode).pipe
        (
            tap
            (
                (data: GameConfig) =>
                {
                    this.gameConfig = data;
                }
            )
        );
    }

    /**
     * Initializes the game.
     *
     * @memberof GameService
     */
    private initGame(): void
    {
        this.gameStateSubject.next('game-init');
    }

    /**
     * Initializes players.
     *
     * @memberof GameService
     */
    initPlayers(): void
    {
        this.situations.forEach
        (
            (situation) =>
            {
                for (const player in situation.players)
                {
                    if (!this.players[player])
                    {
                        this.players[player] = new Player(situation.players[player].displayName);
                    }
                }
            }
        );

        this.playersSubject.next(this.players);
    }

    /**
     * Performs actions when the game is initialized.
     *
     * @private
     * @memberof GameService
     */
    private onGameInit(): void
    {
        // Wait for game mode to resolve
        forkJoin
        (
            this.getGameMode()
        ).subscribe
        (
            () =>
            {
                // Wait for game config to resolve
                forkJoin
                (
                    this.getGameConfig(this.gameMode)
                ).subscribe
                (
                    () =>
                    {
                        // Wait for situations to resolve
                        forkJoin
                        (
                            this.situationService.initSituations(this.gameMode, this.gameConfig),
                        ).subscribe
                        (
                            () =>
                            {
                                this.situationService.situationsObservable.subscribe
                                (
                                    (situations: Situation[]) =>
                                    {
                                        this.situations = situations;
                                        this.initPlayers();
                                    }
                                );

                                this.situationService.currentSituationIndexObservable.subscribe
                                (
                                    (newSituationIndex: number) =>
                                    {
                                        this.onCurrentSituationIndexChange(newSituationIndex);
                                    }
                                );

                                this.router.navigate(['/']);
                            }
                        );
                    }
                );
            }
        );
    }

    /**
     * Performs action when game mode changes.
     *
     * @param {GameMode} newGameMode
     * @memberof GameService
     */
    private onGameModeChanged(newGameMode: GameMode): void
    {
        this.gameMode = newGameMode;
    }

    /**
     * Performs action when the current situation index changes.
     *
     * @param {number} nextSituationIndex The situation index of the next situation.
     * @memberof GameService
     */
    onCurrentSituationIndexChange(nextSituationIndex: number): void
    {
        if (this.isGameOver(nextSituationIndex, this.situations))
        {
            this.gameOver();
        }
    }

    /**
     * Performs actions when the game is started.
     *
     * @private
     * @memberof GameService
     */
    private onGameStarted(): void
    {
        this.router.navigate(['/situation']);
    }

    /**
     * Performs actions when the game ends.
     *
     * @private
     * @memberof GameService
     */
    private onGameOver(): void
    {
        const totalScores = this.judgeService.calculateTotalScores(this.situations);

        for (const player in totalScores)
        {
            this.players[player].score = totalScores[player];
        }

        this.router.navigate(['/score']);
    }
}
